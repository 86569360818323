import * as React from "react"
import { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import Axios from "axios"

import { getLocationStorage, setLocationStorage } from "../../services/locationHelper"

const { slugify } = require("../../services/slugHelper")

const IndustryPage = ({ data, industry }) => {
  const [basePath, setBasePath] = useState("")

  useEffect(() => {
    console.log(industry)
    Axios.get("https://ipinfo.io?token=49ec2e1ac47e01")
      .then(response => {
        const responseData = response.data

        setLocationStorage({
          city: responseData.city,
          region: responseData.region,
          country: responseData.country,
        })

        let locationStorage = getLocationStorage()
        locationStorage.countryCode = locationStorage.country
        const region = data.allRegion.nodes.filter(
          region =>
            locationStorage.region === region.name && locationStorage.countryCode === region.countryCode
        )
        if (region.length > 0) {
          locationStorage.regionCode = region[0].code
        }

        setBasePath(
          (
            locationStorage.country +
            "/" +
            locationStorage.regionCode +
            "/" +
            slugify(locationStorage.city)
          ).toLowerCase()
        )
      })
      .catch(error => {
        console.log(error.message)
      })
  }, [data.allRegion.nodes])

  console.log(`/${basePath}/${industry}`)

  if (basePath) {
    navigate(`/${basePath}/${industry}`)
  }

  return <></>
}

export default IndustryPage

export const cityQuery = graphql`
  query {
    allRegion {
      nodes {
        id
        code
        name
        countryCode
      }
    }
  }
`
