const slugify = string => {
  string = string.toLowerCase()
  return string.replace(/\s+/g, "-")
}

const unSlugify = string => {
  string = string.replace(/-/g, " ")

  const arr = string.split(" ")

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }

  return arr.join(" ")
}

module.exports = { slugify, unSlugify }
